<template lang="pug">
  .input-container
    label {{label}}
      br( v-if="label" )
      input.input( v-bind="$attrs" :value="value" v-on="listeners" :class="{'input__invalid': invalid}" )
</template>

<script>
export default {
  name: 'AppInput',
  inheritAttrs: false,
  props: {
    invalid: {
      type: Boolean,
      default: false,
      required: false
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    label: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-container {
  color: white;
  max-width: 460px;
  display: inline-block;

  .input {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    color: #FF6600;
    font-family: inherit;
    font-size: inherit;
    border: 2px solid white;
    border-radius: 5px;
    padding: 6px 10px;
    margin: 10px 4px;
    height: 2em;
    vertical-align: middle;

    &__invalid {
      border-color: rgb(255, 33, 33);
    }

    // border: none;
    // border-bottom: 2px solid white;
  }

  label {
    margin: 0 8px;
    font-weight: bold;
  }
}
</style>
