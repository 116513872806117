<template lang="pug">
  form.signup-form( @:submit.prevent )
    .email-form( v-show="!passwordSectionIsVisible" )
      span I want a
      AppSelectInput( v-model="mailFrequency" )
        option( value="1") weekly
        option( value="2") monthly
      span email sent to me at:
      AppInput( :invalid="!emailIsValid" v-model="userEmail" type="email" placeholder="email@provider.com" @blur="checkEmailIsValid" @keydown.enter.prevent="setPasswordSectionToVisible" )
      AppButton( @click.prevent="setPasswordSectionToVisible" ) SIGN-UP
        img.go-button-icon( src="../assets/icons/round-navigate_next-24px.svg" )
    .password-form( v-show="passwordSectionIsVisible" )
      span(@click="passwordSectionIsVisible = false" style="margin-right: .5em;") ◀︎
      span You'll probably also want a password:
      AppInput#password-input( :invalid="!passwordIsValid" v-model="userPassword" type="password" @blur="checkPasswordIsValid" @keydown.enter.prevent="createUser" )
      AppButton( @click.prevent="createUser" type="submit" ) DONE ✓
</template>

<script>
import isEmail from 'validator/lib/isEmail'

import { auth, db } from '@/firebase.js'

import AppInput from '@/components/AppInput.vue'
import AppSelectInput from '@/components/AppSelectInput.vue'
import AppButton from '@/components/AppButton.vue'

export default {
  name: 'TheSignupForm',
  components: {
    AppInput,
    AppSelectInput,
    AppButton
  },
  props: {
    gaSource: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      passwordSectionIsVisible: false,
      emailIsValid: true,
      passwordIsValid: true,
      mailFrequency: 1,
      userEmail: '',
      userPassword: ''
    }
  },
  methods: {
    checkEmailIsValid () {
      this.emailIsValid = isEmail(this.userEmail)
    },
    checkPasswordIsValid () {
      this.passwordIsValid = this.userPassword.length > 5
    },
    setPasswordSectionToVisible () {
      this.checkEmailIsValid() // if the user has not left focus this will triger the emailIsValid
      if (!this.emailIsValid) return
      this.passwordSectionIsVisible = true
      this.$nextTick(() => {
        document.getElementById('password-input').focus()
      })
      // track action using GA
      this.$ga.event({
        eventCategory: 'register',
        eventAction: 'Enter Email and Frequency'
        // eventLabel: 'label',
        // eventValue: 123
      })
    },
    createUser () {
      this.checkPasswordIsValid() // if the user has not left focus this will triger the passwordIsValid
      return auth.createUserWithEmailAndPassword(this.userEmail, this.userPassword)
        .then(({ user }) => {
          // track action using GA
          this.$ga.event({
            eventCategory: 'register',
            eventAction: 'Create Account',
            eventLabel: this.gaSource
            // eventLabel: 'label',
            // eventValue: 123
          })
          return setTimeout(this.setUserEmailFrequency(user.uid), 1000) // TODO: Fix this
        })
        .catch(err => {
          alert(err.message)
          console.error(err)
        })
    },
    setUserEmailFrequency (uid) {
      if (!uid) return alert('not logged in')
      db
        .collection('users')
        .doc(uid)
        .set({
          settings: {
            frequency: this.mailFrequency
          }
        }, { merge: true })
        .then(() => {
          this.$router.push({ name: 'feeds' })
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.signup-form {
  // font-size: 36px;
  color: white;
  font-family: Helvetica, sans-serif;
  font-size: 1.3rem;
}

.go-button-icon {
  height: 2em;
  margin: -0.5em 0;
  vertical-align: sub;
  margin-right: -1em;
}
</style>
