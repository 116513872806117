<template lang="pug">
  .button-container
      button( v-bind="$attrs" v-on="listeners" :style="style")
        slot
</template>

<script>
export default {
  name: 'AppButton',
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      reuired: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => this.$emit('input', e.target.value)
      }
    },
    style () {
      return (() => {
        return [
          this.color ? 'color: black;' : '',
          this.color ? `border-color: ${this.color};` : '',
          this.color ? `background-color: ${this.color};` : ''
        ].join(' ')
      })()
    }
  }
}
</script>

<style lang="scss" scoped>
.button-container {
  color: white;
  max-width: 460px;
  display: inline-block;

  button {
    font-family: 'Helvetica-Neue', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    color: #FF6600;
    // font-family: inherit;
    font-size: inherit;
    border: 2px solid white;
    border-radius: 5px;
    padding: 0 1.2em;
    margin: 10px 8px;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
    font-weight: bold;

    // border-radius: 0;
    // border: 3px solid lightgray;
    // background-color: orangered;
    // color: white;
    // font-weight: 900;

    &:active {
      background-color: white;
      color: black;
    }
  }
}
</style>
