<template lang="pug">
  .landing-page
    router-link.login-link( to="login" ) Login
    section.signup-section
      img.signup-section__logo( alt="Feedlivery logo" src="../assets/logo-animated.svg" )
      h1.signup-section__title One email every week with posts from the feeds you follow.
      TheSignupForm(gaSource="bottomSignup" )
    section.info-section
      h1.info-section__title How it works:
      .info-block.info-block--left
        .info-block__text-container
          h2.info-block__sub-title Get your weekly posts by email.
          h1.info-block__title Not another app
          p.info-block__body Just a weekly/monthly email with the posts from blogs you love, if there are no new ones we won't email you. The whole idea is to bother you as little as possible and keep it stupid simple.
        picture
          source( srcset="../assets/cover.webp" type="image/webp" )
          source( srcset="../assets/cover.png" type="image/png" )
          img.info-block__img( src="../assets/cover.png" style="transform: scale(1.25);" )
      .info-block.info-block--right
        .info-block__text-container
          h2.info-block__sub-title Keep track of smaller blogs
          h1.info-block__title It does not have to be complex.
          p.info-block__body You register an account using your email and add some feeds to your feedlist. Just set the desired frequency and new posts from the feeds in your list will land in your inbox once a week/month.
        picture
          source( srcset="../assets/code.webp" type="image/webp" )
          source( srcset="../assets/code.png" type="image/png" )
          img.info-block__img.info-block__img--glow( src="../assets/code.png" )
      br
      .info-block.info-block--center
        .info-block__text-container
          h2.info-block__sub-title Plain old RSS feeds
          h1.info-block__title Build on two robust and timeless protocols
          p.info-block__body Feedlivery is build upon two basic technologies, Email and RSS. Simple, distributed, widly used and stable. You can always export your list of feeds when you want to move away from Feedlivery or temporairy disable your weekly/monthly email.
        TheSignupForm( gaSource="bottomSignup" )
    footer.footer-section
      a( href="mailto:will@feedlivery.com" ) Contact
      //- br
      //- h2.faq-item__title Why?
      //- p
      //-   | I freaking love smaller blogs, I've seen some of the most creative stuff on there. From people creating elaborate living wikis about nice interests such as blue light exposure and people transforming their blog posts into pieces of art, stuff that simply would not be possible on platforms like medium.
      //-   | I would run into plenty of these awesome blogs while browsing HN but always forget them or put into an RSS app that I never ended up using because the blogs I followed only ended up posting something every month or so.
      //-   | This service just sends you a very simple email once a week or month with new content from your feeds if there is any.
      //- h2.faq-item__title How does this work?
      //- p
      //-   | You register an account using the form above and add feeds to your feedlist. You set the desired frequency and new posts from the feeds in your list will land in your inbox once a week/month.
      //- h2.faq-item__title What's happening to my data?
      //- p
      //-   | Your credentials are being stored by Firebase on Google servers. On the <a href="/settings"> settings page </a> there is a button to delete your account, this will permanently delete your credentials, settings and feed data. <b> We do not use your data for anything else than delivering your feeds to you. </b>
      //- h2.faq-item__title I have another question.
      //- p
      //-   | No problem, feel free to let me know trough <a href="mailto:will@feedlivery.com" >email</a> or <a href="https://twitter.com/will_rut"> Twitter </a>.
</template>

<script>
// @ is an alias to /src
import TheSignupForm from '@/components/TheSignupForm.vue'

export default {
  name: 'home',
  components: {
    TheSignupForm
  }
}
</script>

<style lang="scss" scoped>
.landing-page {
  padding: 40px 20px;
}

.signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 200px);
  font-family: 'Quicksand', 'Avenir', Helvetica, Arial, sans-serif;
  padding-top: 200px;

  &__logo {
    width: 600px;
    max-width: 90vw;
    margin-top: 0px;

    @media (max-width: 600px) {
      margin-top: 0;
    }
  }

  &__title {
    color: white;
    // font-family: 'Quicksand-Bold';
    text-shadow: 0 0 4px rgba(235,224,51,0.50);
    margin-top: 0;
  }
}

.info-section {
  display: inline-block;
  max-width: 960px;
  font-family: "Helvetica Neue";

  &__title {
    color: white;
    font-size: 3rem;
    margin: 1em 0;
    text-decoration: underline;
  }

}

.info-block {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 40px 0;

  &__text-container {
    float: left;
    margin: 10px 20px;
  }

  &__title {
    margin: 0.1em 0;
    font-size: 1.8rem;
  }

  &__sub-title {
    color: #FF6600;
    margin: 0 0;
    font-size: 1.1rem;
    font-weight: bold;
  }

  &__body {
    margin: 0 0;
    font-size: 1rem;
    line-height: 1.7em;
    color: #FFFFFF;
  }

  &__img {
    max-width: 300px;
    margin: 10px 20px;
    float: right;
    border-radius: 5px;

    &--glow {
      box-shadow: rgba(255, 102, 0, 0.384) 0px 0px 20px;
    }
  }

  &--left {
    text-align: left;
    flex-direction: row;

    @media (max-width: 600px) {
      text-align: center;
      flex-direction: column;
    }
  }

  &--right {
    text-align: left;
    flex-direction: row-reverse;

    @media (max-width: 600px) {
      text-align: center;
      flex-direction: column;
    }
  }

  &--center {
    text-align: center;
    flex-direction: column;
  }
}

.footer-section {
  margin-top: 60px;
  padding: 20px 0;
  opacity: 0.6;

  a {
    color: white;
  }
}

.login-link {
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-weight: bold;
  text-decoration: none;
}
</style>
