<template lang="pug">
  .select-container
    label {{label}}
      select( v-bind="$attrs" :value="value" v-on="listeners" )
        slot
</template>

<script>
export default {
  name: 'AppSelectInput',
  inheritAttrs: false,
  props: {
    value: {
      default: '',
      required: true
    },
    label: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-container {
  color: white;
  max-width: 460px;
  display: inline-block;

  select {
    box-sizing: border-box;
    appearance: none;
    background-color: black;
    color: #FF6600;
    font-family: inherit;
    font-size: inherit;
    border: 2px solid white;
    border-radius: 5px;
    padding: 6px 4px;
    margin: 10px 8px;
    height: 2em;
    vertical-align: middle;
  }

  label {
    font-weight: bold;
  }
}
</style>
